import { getTokenProperty, logged } from "../../auth/helpers"
import { ROLES } from "../../constants/roles"
import { slugify } from "./slugify"

export const handlerRoutePath = (path) => {
  // debugger

  const isSession = logged();
  if (!isSession) return path[0]

  const roleId = getTokenProperty("role")
  const client = getTokenProperty("client")

  if (typeof path === "string") return path

  if (roleId !== ROLES.CLIENT || !client) return path[0]

  const slugifyClient = slugify(client)
  let clientPath = path[1]

  //detectar si clientPath empieza con "/"
  if (clientPath.startsWith("/")) {
    clientPath = clientPath.slice(1)
  }

  let clientPathParsed = clientPath.replace(":id", slugifyClient)

  return "/" + clientPathParsed
}