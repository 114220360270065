import React, { Component } from 'react'
import ReactDOM from 'react-dom';

class LoadingOverlay extends Component {
	
	componentDidMount() {
		ReactDOM.render(this.loading(), document.getElementById("body-spinner"));
	}

	componentWillUnmount() {
		ReactDOM.unmountComponentAtNode(document.getElementById("body-spinner"));
	}

	loading = () => {
		return (
			<div className="loader-pendulums-container-overlay">
				<div className="bg-spinner">
					<div className="container mt-5 pt-5">
						<div className="row mt-5 pt-5">
							<div className="col-12 text-center">
								<div className="flex-container">
									<div className="rainbow">
										<div className="circle1"></div>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div>
		);
	}

	render() {
		return (
			<></>
		);
	}
}

export default LoadingOverlay;