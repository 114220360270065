import { httpApiGet, httpApiPost, httpApiDelete, httpApiUploadFile, httpApiPatch, httpApiPut } from "../helpers/axiosPrivate";
import { sortArrayByLabel } from "../helpers/sortArrayByLabel";

const initialState = {
	appDataLoaded: false,
	roles: [],
	internalCategoriesSelect: [],
	internalCategories: [],
	discountRanges: [],
	clients: [],
	users: [],
	areas: [],
	paymentConditions: [],
	orderNotes: [],
	suppliers: [],
	printingTechniques: [],
	numberColors: [],
	colors: [],
	reloadPagination: false,
	loading: false,
	loadingOverlay: false,
	error: false,
	message: '',
	isSubmitting: false,
}

const GET_APP_DATA_SUCCESS = 'GET_APP_DATA_SUCCESS'
const GET_SELECT_DATA_SUCCESS = 'GET_SELECT_DATA_SUCCESS'
const APP_DATA_FAILURE = 'APP_DATA_FAILURE'
const START_LOADING_APP_DATA = 'START_LOADING_APP_DATA'
const STOP_LOADING_APP_DATA = 'STOP_LOADING_APP_DATA'
const START_LOADING_APP_DATA_OVERLAY = 'START_LOADING_APP_DATA_OVERLAY'
const STOP_LOADING_APP_DATA_OVERLAY = 'STOP_LOADING_APP_DATA_OVERLAY'
const RESET_APP_DATA_MESSAGE = 'RESET_APP_DATA_MESSAGE'
const SHOW_APP_DATA_MESSAGE = 'SHOW_APP_DATA_MESSAGE'
const GET_CLIENTS_APP_DATA_SUCCESS = 'GET_CLIENTS_APP_DATA_SUCCESS'
const START_RELOAD_PAGINATION = 'START_RELOAD_PAGINATION'
const STOP_RELOAD_PAGINATION = 'STOP_RELOAD_PAGINATION'
const SET_IS_SUBMITTING = 'SET_IS_SUBMITTING'
const RESET_CLIENTS = 'RESET_CLIENTS'
const GET_COLORS_APP_DATA_SUCCESS = 'GET_COLORS_APP_DATA_SUCCESS';
const GET_DISCOUNT_RANGES = 'GET_DISCOUNT_RANGES';
const SAVE_DISCOUNT_RANGES = 'SAVE_DISCOUNT_RANGES';
const SAVE_DISCOUNT_RANGES_FAILURE = 'SAVE_DISCOUNT_RANGES_FAILURE';

export default function reducer(state = initialState, action) {
	switch (action.type) {
		case GET_APP_DATA_SUCCESS:
			return {
				...state,
				appDataLoaded: true,
				...action.payload
			}
		case GET_SELECT_DATA_SUCCESS:
			return {
				...state,
				...action.payload
			}
		case APP_DATA_FAILURE:
			return {
				...state,
				message: action.message,
				error: true
			}
		case START_LOADING_APP_DATA:
			return {
				...state,
				loading: true,
			}
		case STOP_LOADING_APP_DATA:
			return {
				...state,
				loading: false,
			}
		case START_LOADING_APP_DATA_OVERLAY:
			return {
				...state,
				loadingOverlay: true,
			}
		case STOP_LOADING_APP_DATA_OVERLAY:
			return {
				...state,
				loadingOverlay: false,
			}
		case SHOW_APP_DATA_MESSAGE:
			return {
				...state,
				message: action.message,
				error: action.payload
			}
		case RESET_APP_DATA_MESSAGE:
			return {
				...state,
				message: '',
				error: false
			}
		case GET_CLIENTS_APP_DATA_SUCCESS:
			return {
				...state,
				message: action.payload.message,
				clients: [...state.clients, ...action.payload.clients]
			}
		case START_RELOAD_PAGINATION:
			return {
				...state,
				reloadPagination: true
			}
		case STOP_RELOAD_PAGINATION:
			return {
				...state,
				reloadPagination: false
			}

		case SET_IS_SUBMITTING:
			return {
				...state,
				isSubmitting: action.payload
			}

		case RESET_CLIENTS:
			return {
				...state,
				clients: action.payload
			}
		case GET_COLORS_APP_DATA_SUCCESS:
			return {
				...state,
				colors: action.payload
			}
		case GET_DISCOUNT_RANGES:
			return {
				...state,
				discountRanges: action.payload.discountRanges
			}

		case SAVE_DISCOUNT_RANGES:
			return {
				...state,
				discountRanges: action.payload.discountRanges,
				message: action.payload.message,
				loadingOverlay: false
			}
		case SAVE_DISCOUNT_RANGES_FAILURE:
			return {
				...state,
				loadingOverlay: false,
				error: true,
				message: action.payload.message
			}

		default:
			return state;
	}
}

export const getAppDataAction = () => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA,
	})
	Promise.all([
		httpApiGet('internal-categories'),
		httpApiGet('clients/all/quotation'),
		// httpApiGet('discount-range')
	])
		.then(values => {
			const data = {
				internalCategories: values[0],
				clients: values[1],
				// discountRanges: values[2]
			}
			if (data) {
				dispatch({
					type: GET_APP_DATA_SUCCESS,
					payload: data,
				});
			}
		}).catch(reason => {
			dispatch({
				type: APP_DATA_FAILURE,
				message: 'Hubo un error cargando los datos de la aplicación',
			});
		})
		.finally(
			dispatch({
				type: STOP_LOADING_APP_DATA,
			})
		)
}

export const getSelectDataAction = () => async (dispatch, getState) => {
	Promise.all([
		httpApiGet('app-data/payment-conditions'),
		httpApiGet('app-data/order-notes'),
		httpApiGet('app-data/printing-techniques'),
		httpApiGet('app-data/number-colors'),
		httpApiGet('suppliers'),
		httpApiGet('users/all/'),
		httpApiGet('area/active')
	]).then(values => {
		const data = {
			paymentConditions: values[0],
			orderNotes: values[1],
			printingTechniques: values[2],
			numberColors: values[3],
			suppliers: values[4],
			users: values[5],
			areas: values[6]
		}
		if (data) {
			dispatch({
				type: GET_SELECT_DATA_SUCCESS,
				payload: data,
			});
		}
	}).catch(reason => {
		dispatch({
			type: APP_DATA_FAILURE,
			message: 'Hubo un error cargando los datos de la aplicación',
		});
	});
}

export const getCategoriesAction = (withLoading = false) => async (dispatch, getState) => {
	if (withLoading) {
		dispatch({
			type: START_LOADING_APP_DATA_OVERLAY,
		});
	}
	try {
		const data = await httpApiGet('internal-categories');
		if (data) {
			const internalCategoriesApi = data.filter(category => !category.isProductInternal)
			let internalCategoriesSelect = internalCategoriesApi.map(ic => ({ label: ic.name, value: ic.id }))
			internalCategoriesSelect = sortArrayByLabel(internalCategoriesSelect)

			dispatch({
				type: GET_APP_DATA_SUCCESS,
				payload: {
					internalCategories: data,
					internalCategoriesSelect
				},
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		if (withLoading) {
			dispatch({
				type: STOP_LOADING_APP_DATA_OVERLAY,
			});
		}
	}
}

export const saveCategoriesAction = (internalCategoriesEdit) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiPost('internal-categories', internalCategoriesEdit);
		if (data) {
			dispatch({
				type: GET_APP_DATA_SUCCESS,
				payload: { internalCategories: data },
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
	}
}

// clientes
export const getClientsAction = () => async (dispatch, getState) => {
	httpApiGet('clients/all/quotation')
		.then(values => {
			if (values) {
				const data = {
					clients: values
				}
				dispatch({
					type: GET_APP_DATA_SUCCESS,
					payload: data,
				});
			}
		}).catch(reason => {
			dispatch({
				type: APP_DATA_FAILURE,
				message: 'Hubo un error cargando los datos de los clientes',
			});
		});
}

export const saveOneClientAction = (client, action) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiPost('clients/add', client);
		if (data) {
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: {
					clients: data,
					message: 'Cliente agregado correctamente'
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
			action()
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}


export const loadClientsAction = (url, clientsFile) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiUploadFile(url, clientsFile);
		if (data) {
			if (data.data) {
				dispatch({
					type: GET_CLIENTS_APP_DATA_SUCCESS,
					payload: {
						clients: data.data,
						message: data.message
					},
				});
			}
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const saveOneUserAction = (referent) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiPost('users', { name: referent });
		if (data) {
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: {
					referents: data,
					message: 'Uusuario agregado correctamente'
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}


export const loadUsersAction = (referentsFile) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiUploadFile('users/load', referentsFile);
		if (data && data.created > 0) {
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: {
					referents: data.data,
					message: data.message
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const deleteClientAction = (clientId) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiDelete(`clients/${clientId}`);
		if (data) {
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: {
					clients: data,
					message: 'Cliente eliminado correctamente'
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const updateClientAction = (clientId, client, successAction) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const data = await httpApiPatch(`clients/${clientId}`, client);
		if (data) {
			dispatch({
				type: GET_CLIENTS_APP_DATA_SUCCESS,
				payload: {
					clients: data,
					message: 'Cliente actualizado correctamente'
				},
			});
			dispatch({
				type: START_RELOAD_PAGINATION,
			});
			successAction()
		}
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const updateMarkupAction = (clientId, markup) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const body = { markup, clientId };
		await httpApiPut('client-config', body);
		dispatch({
			type: START_RELOAD_PAGINATION,
		});
	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}
export const updateQuoterAction = (clientId, newQuoter) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		const body = { ejecutivoId: newQuoter };
		await httpApiPut(`clients/${clientId}/ejecutivo`, body);
		dispatch({
			type: START_RELOAD_PAGINATION,
		});

	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const updateProfileImageClient = (id, formData) => async (dispatch, getState) => {
	dispatch({
		type: START_LOADING_APP_DATA_OVERLAY,
	});
	try {
		await httpApiUploadFile(`clients/${id}/profileImage`, formData);
		dispatch({
			type: START_RELOAD_PAGINATION,
		});

	} catch (error) {
		dispatch({
			type: APP_DATA_FAILURE,
			message: error.message,
		});
	} finally {
		dispatch({
			type: STOP_LOADING_APP_DATA_OVERLAY,
		});
		dispatch({
			type: STOP_RELOAD_PAGINATION,
		});
	}
}

export const resetAppDataMessage = () => async (dispatch, getState) => {
	dispatch({
		type: RESET_APP_DATA_MESSAGE,
	});
}

export const resetClients = () => async (dispatch, getState) => {
	const clients = await httpApiGet('clients/all/quotation')
	dispatch({
		type: RESET_CLIENTS,
		payload: clients
	});
}

export const loadAppDataColors = () => async (dispatch, getState) => {
	httpApiGet('colors')
		.then(values => {
			if (values) {
				dispatch({
					type: GET_COLORS_APP_DATA_SUCCESS,
					payload: values,
				});
			}
		}).catch(reason => {
			dispatch({
				type: APP_DATA_FAILURE,
				message: 'Hubo un error cargando los datos de los colores',
			});
		});
}

export const getDiscountRangesAction = () => async (dispatch, getState) => {
	httpApiGet('discount-range')
		.then(value => {
			dispatch({
				type: GET_DISCOUNT_RANGES,
				payload: {
					discountRanges: value
				}
			})
		})
		.catch(error => {
			console.log("error al obtener descuentos", error)
		})
}


export const saveDiscountRanges = (values) => async (dispatch, getState) => {
	let discountRanges = getState().appData.discountRanges

	if (discountRanges.length === 0) {
		dispatch({
			type: START_LOADING_APP_DATA_OVERLAY
		})
		httpApiPost('discount-range', values)
			.then(values => {
				if (values) {
					dispatch({
						type: SAVE_DISCOUNT_RANGES,
						payload: {
							discountRanges: values,
							message: "Descuentos guardados correctamente"
						}
					})
				}
			})
			.catch(err => {
				console.log(err)
				dispatch({
					type: SAVE_DISCOUNT_RANGES_FAILURE,
					message: "Error al cargar descuentos"
				})
			})
	} else {
		httpApiPatch('discount-range', values)
			.then(values => {
				if (values) {
					dispatch({
						type: SAVE_DISCOUNT_RANGES,
						payload: {
							discountRanges: values,
							message: "Descuentos guardados correctamente"
						}
					})
				}
			})
			.catch(err => {
				console.log(err)
				dispatch({
					type: SAVE_DISCOUNT_RANGES_FAILURE,
					message: "Error al cargar descuentos"
				})
			})
	}


}





