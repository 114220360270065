const passwordRegex = '^(?=.*[0-9])(?=.*[a-z])(?=.*[A-Z])(?=.*[.@$!%^*?&])([a-zA-Z0-9.@$!%^*?&]{8,})$';
const urlRegex = /(https?:\/\/)?(www\.)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)|(https?:\/\/)?(www\.)?(?!ww)[-a-zA-Z0-9@:%._+~#=]{2,256}\.[a-z]{2,4}\b([-a-zA-Z0-9@:%_+.~#?&//=]*)/;
const emailRegex = /(?:[a-z0-9!#$%&'*+/=?^_`{|}~-]+(?:\.[a-z0-9!#$%&'*+/=?^_`{|}~-]+)*|"(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21\x23-\x5b\x5d-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])*")@(?:(?:[a-z0-9](?:[a-z0-9-]*[a-z0-9])?\.)+[a-z0-9](?:[a-z0-9-]*[a-z0-9])?|\[(?:(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9]))\.){3}(?:(2(5[0-5]|[0-4][0-9])|1[0-9][0-9]|[1-9]?[0-9])|[a-z0-9-]*[a-z0-9]:(?:[\x01-\x08\x0b\x0c\x0e-\x1f\x21-\x5a\x53-\x7f]|\\[\x01-\x09\x0b\x0c\x0e-\x7f])+)\])/;

const hasNCharacters = (text, number) => {
	return text.length >= number;
};

const hasUpperCase = (text) => {
	return (/[A-Z]/.test(text));
};

const hasLowerCase = (text) => {
	return (/[a-z]/.test(text));
};

const hasNumber = (text) => {
	return (/[0-9]/.test(text));
};

const hasSpecialCharacter = (text) => {
	return (/[@$!%^*?&]/.test(text));
};

const containsWhiteSpace = (text) => {
	return /\s/g.test(text);
};

const removeWhiteSpaces = (text) => {
	return text.replace(/\s/g, "");
};

const capitalizeFirstLetter = (text) => {
	if (text) {
		return text.charAt(0).toUpperCase() + text.slice(1);
	}
}

const uppercaseFirstLowercaseRest = (text) => {
	return text.charAt(0).toUpperCase() + text.slice(1).toLowerCase();
  };

  const normalizeName = (name) => {
	return name
	  .toLowerCase() 
	  .split(' ') 
	  .map(text => text.charAt(0).toUpperCase() + text.slice(1))
	  .join(' '); 
  };

const isEmail = (email) => {
	return emailRegex.test(email);
};

const onlyNumbers = (current, text) => {
	if (!text) {
		return current;
	}
	return text.replace(/[^0-9]/g, '')
};

const startsWith = (string, char) => {
	if (!string || string.length === 0) {
		return false;
	}
	return string[0] === char;
};

const stringContains = (string, subString) => {
	if (!string || string.length === 0 || !subString || subString.length === 0) {
		return false;
	}
	return string.toLowerCase().includes(subString.toLowerCase());
};

export {
	hasNCharacters,
	hasUpperCase,
	hasLowerCase,
	hasNumber,
	hasSpecialCharacter,
	containsWhiteSpace,
	removeWhiteSpaces,
	capitalizeFirstLetter,
	normalizeName,
	uppercaseFirstLowercaseRest,
	isEmail,
	onlyNumbers,
	startsWith,
	stringContains,
	passwordRegex,
	urlRegex,
};