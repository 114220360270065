export const slugify = (text) => {
  return text
    .normalize('NFD') // Normaliza caracteres con acentos
    .replace(/[\u0300-\u036f]/g, '') // Elimina marcas de acentos
    .replace(/ñ/g, 'n') // Reemplaza la ñ
    .replace(/[^a-zA-Z0-9]+/g, '-') // Reemplaza caracteres especiales por guiones
    .toLowerCase() // Convierte a minúsculas
    .replace(/^-+|-+$/g, ''); // Elimina guiones al inicio o final
}

