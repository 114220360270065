import React, { useState, useRef } from "react";
import PasswordValidatorTooltip from "./PasswordValidatorTooltip";
import { onlyNumbers } from "./StringValidator";

const CustomInput = (props) => {
  const {
    handleChange,
    id,
    type,
    values,
    placeholder,
    className = "form-control",
    lg = false,
    handleBlur,
    autoComplete = "on",
    max = null,
    touched,
    errors,
    isPassword,
    withPasswordTooltip = false,
    tooltipPlacement = "left",
    options,
    disabled,
    readOnly,
  } = props;
  const passwordRef = useRef(null);
  const [showPassword, setShowPassword] = useState(false);
  const [showTooltip, setShowTooltip] = useState(false);

  const handleChangeNumber = (e) => {
    if (e.target.value === "") {
      handleChange(e);
      return;
    }

    const result = onlyNumbers(values[id], e.target.value);
    if (result && result !== "" && result !== values[id]) {
      handleChange(e);
    }
  };

  function getValueByPath(obj, path) {
    const keys = path.split('.'); // Divide el string en partes
    let result = obj; // Comienza desde el objeto

    for (let key of keys) {
      // Si el valor es un número, convierte el string a número
      key = isNaN(key) ? key : Number(key);

      // Si el valor existe dentro del objeto, continua
      if (result[key] !== undefined) {
        result = result[key];
      } else {
        // Si no existe, retorna undefined
        return undefined;
      }
    }

    return result;
  }

  const renderElements = (type) => {
    switch (type) {
      case "number":
        return (
          <input
            value={getValueByPath(values, id)}
            onChange={(e) => handleChangeNumber(e)}
            id={id}
            name={id}
            type={type}
            placeholder={placeholder}
            max={max}
            onBlur={handleBlur}
            disabled={disabled}
            readOnly={readOnly}
            className={`${className} ${lg ? " form-control-lg " : " "} ${errors[id] && touched[id] ? " input-error " : ""
              }`}
            aria-describedby={id}
          />
        );
      case "select":
        return (
          <>
            {/* <button onClick={() => console.log({id, options, currentValue:getValueByPath(values, id)})}>123</button> */}
            <select
              value={getValueByPath(values, id)}
              onChange={(e) => handleChangeNumber(e)}
              id={id}
              name={id}
              placeholder={placeholder}
              onBlur={handleBlur}
              disabled={disabled}
              className={`${className} ${lg ? " form-control-lg " : " "} ${errors[id] && touched[id] ? " input-error " : ""
                } ${disabled ? "text-muted" : ""}`}
            >
              <option value="">Seleccione una opción</option>
              {options.map((option) => (
                <option
                  key={option.value}
                  value={option.value}
                  disabled={option.disabled}
                >
                  {option.label}
                </option>
              ))}
            </select>
          </>
        );
      case "text-area":
        return (
          <textarea
            value={values[id]}
            onChange={handleChange}
            id={id}
            name={id}
            placeholder={placeholder}
            maxLength={150}
            onBlur={handleBlur}
            disabled={disabled}
            readOnly={readOnly}
            className={`${className} ${lg ? " form-control-lg " : " "} ${errors[id] && touched[id] ? " input-error " : ""
              }`}
            aria-describedby={id}
            style={{ height: "75px", overflowWrap: "break-word", resize: "none" }}
          />
        );
      default:
        return (
          <input
            // value={values[id]}
            value={getValueByPath(values, id)}
            onChange={handleChange}
            id={id}
            name={id}
            type={type}
            placeholder={placeholder}
            autoComplete={autoComplete}
            onBlur={handleBlur}
            disabled={disabled}
            readOnly={readOnly}
            className={`${className} ${lg ? " form-control-lg " : " "} ${errors[id] && touched[id] ? " input-error " : ""
              }`}
            aria-describedby={id}
          />
        );
    }
  };

  return (
    <>
      {isPassword ? (
        <>
          <div className="input-group" style={{ width: "99%" }}>
            <input
              ref={passwordRef}
              onBlur={(e) => {
                handleBlur(e);
                if (withPasswordTooltip) {
                  setShowTooltip(false);
                }
              }}
              onFocus={(e) => {
                if (withPasswordTooltip) {
                  setShowTooltip(true);
                }
              }}
              value={values[id]}
              onChange={handleChange}
              id={id}
              name={id}
              placeholder={placeholder}
              className={`${className} ${lg ? " form-control-lg " : " "} ${errors[id] && touched[id] ? " input-error " : ""
                }`}
              style={{ backgroundImage: "none" }}
              type={showPassword ? "text" : "password"}
              aria-describedby={id}
            />
            {withPasswordTooltip && (
              <PasswordValidatorTooltip
                showTooltip={showTooltip}
                passwordRef={passwordRef}
                password={values[id]}
                placement={tooltipPlacement}
              />
            )}
            <div className="input-group-append show-password">
              <span onClick={(e) => setShowPassword(!showPassword)}>
                <i
                  className={!showPassword ? "bi bi-eye-slash" : "bi bi-eye"}
                ></i>
              </span>
            </div>
          </div>
        </>
      ) : (
        renderElements(type)
      )}
      {errors[id] && touched[id] && (
        <p className="text-error text-start mt-2">{errors[id]}</p>
      )}
    </>
  );
};

export default CustomInput;
