import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import LoadingOverlayed from '../utils/LoadingOverlay'
import { useFormik } from "formik";
import { resetPasswordSchema } from "../../schemas/auth";
import CustomInput from "../utils/CustomInput";
import useWindowDimensions from '../utils/hooks/useWindowDimensions';
import { useDispatch } from 'react-redux';
import {
	getProfileAction,
} from '../../services/redux/profileRedux';
import { DASHBOARD_ROUTE } from '../../routes/dashboardRoutes';
import { authenticate, logged } from '../../auth/helpers';
import { handlerRoutePath } from '../utils/handlerRoutePath';

const ResetForm = ({ history, token, url, buttonMessage }) => {
	const dispatch = useDispatch();
	const [showLoadingOverlayed, setShowLoadingOverlayed] = useState(false);
	const { width } = useWindowDimensions();

	const onSubmit = async (values, actions) => {
		setShowLoadingOverlayed(true);
		axios({
			method: 'PATCH',
			url,
			headers: {
				'Content-Type': 'application/json'
			},
			data: {
				token,
				password: values.password,
				passwordConfirmation: values.password_confirmation,
			}
		})
			.then(response => {
				authenticate(response, () => {
					logged() && redirectHome();
				});
			})
			.catch(error => {
				setShowLoadingOverlayed(false);
				toast.error(error.response.data.message);
			})
	};

	const redirectHome = () => {
		dispatch(getProfileAction());
		const route = handlerRoutePath(DASHBOARD_ROUTE.HOME);
		history.push(route);
	}

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			password: "",
			password_confirmation: "",
		},
		validationSchema: resetPasswordSchema,
		onSubmit,
	});

	return (
		<>
			<ToastContainer
				position="bottom-right"
			/>

			{showLoadingOverlayed &&
				<LoadingOverlayed />
			}
			<form onSubmit={handleSubmit} autoComplete="off" className="h-100">
				<div className="mb-4">
					<label className="form-label login" htmlFor="password">Nueva contraseña</label>
					<CustomInput
						handleChange={handleChange}
						id="password"
						isPassword
						values={values}
						className="form-control login"
						handleBlur={handleBlur}
						touched={touched}
						errors={errors}
						withPasswordTooltip
						tooltipPlacement={width > 770 ? 'left' : 'top'}
					/>
				</div>
				<div className="mb-4">
					<label className="form-label login" htmlFor="password_confirmation">Confirmar contraseña</label>
					<CustomInput
						handleChange={handleChange}
						id="password_confirmation"
						isPassword
						values={values}
						className="form-control login"
						handleBlur={handleBlur}
						touched={touched}
						errors={errors}
					/>
				</div>

				<button type="submit" disabled={isSubmitting} className="w-100 btn btn-lg btn-primary mt-2 mb-5">{buttonMessage}</button>
				<div className="passwordRecover">
					<Link to='/ingresar'>
						Iniciar sesión
					</Link>
				</div>
			</form>

		</>
	)
}

export default withRouter(ResetForm);
