import React, { useEffect } from 'react';
import { Redirect } from 'react-router-dom';
import { logged } from '../../auth/helpers';
import { useDispatch } from 'react-redux';
import SignInForm from '../../components/auth/SignInForm'
import { resetQuotationProductQuotation } from '../../services/redux/quotationRedux';
import { handlerRoutePath } from '../../components/utils/handlerRoutePath';
import { DASHBOARD_ROUTE } from '../../routes/dashboardRoutes';

const Signin = () => {
	const dispatch = useDispatch();

	useEffect(() => {
		document.getElementById('body').classList.add('loginContainer');
		dispatch({
			type: 'CLEAR_REDUX',
		});
	}, []);

	return (
		<>
			{logged() ? <Redirect to={() => handlerRoutePath(DASHBOARD_ROUTE.HOME)} /> : null}
			<main className="form-signin">
				<div>
					<div className="signIn">
						<a href="https://viacotone.com/" target='_blank' className='d-flex justify-content-center' style={{ textDecoration: 'none', cursor: 'pointer', marginLeft: '10px' }}>
							<img className="logInLogoUno" src="/assets/img/logo_vertical_new.png" alt="" />
						</a>
						<div className="col-xl-4 col-lg-6 col-md-6 col-12 mx-auto singin-form">
							<SignInForm />
						</div>
						<img className="logInLogoDos" src="/assets/img/logo_viacottone_new.png" alt="Logo Viacotone" />
					</div>
				</div>
			</main>
		</>
	)
}

export default Signin;
