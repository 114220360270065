import React, { useState } from 'react';
import { Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { authenticate, logged } from '../../auth/helpers';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.min.css';
import LoadingOverlayed from '../utils/LoadingOverlay'
import { useDispatch } from 'react-redux';
import {
	getProfileAction,
} from '../../services/redux/profileRedux';
import { useFormik } from "formik";
import { loginSchema } from "../../schemas/auth";
import CustomInput from "../utils/CustomInput";
import { DASHBOARD_ROUTE } from '../../routes/dashboardRoutes';
import { formatAxiosError } from '../../services/helpers/axiosPrivate';
import { getAppDataAction, getDiscountRangesAction, getSelectDataAction } from '../../services/redux/appDataRedux';
import { handlerRoutePath } from '../utils/handlerRoutePath';

const SignInForm = ({ history, location }) => {
	const dispatch = useDispatch();
	const [showLoadingOverlayed, setShowLoadingOverlayed] = useState(false);

	const onSubmit = async (values, actions) => {
		setShowLoadingOverlayed(true);
		axios({
			method: 'POST',
			url: `${process.env.REACT_APP_API}/auth/login`,
			headers: {
				'Content-Type': 'application/json'
			},
			data: {
				email: values.email.toLowerCase(),
				password: values.password
			}
		})
			.then(response => {
				authenticate(response, () => {
					if (logged()) {
						redirectHome();
					}
				});
			})
			.catch(error => {
				setShowLoadingOverlayed(false);
				toast.error(formatAxiosError(error.response.data.message));
			})
	};

	const {
		values,
		errors,
		touched,
		isSubmitting,
		handleBlur,
		handleChange,
		handleSubmit,
	} = useFormik({
		initialValues: {
			email: "",
			password: "",
		},
		validationSchema: loginSchema,
		onSubmit,
	});

	const redirectHome = () => {
		dispatch(getAppDataAction());
		dispatch(getSelectDataAction());
		dispatch(getProfileAction());
		dispatch(getDiscountRangesAction());

		const url = location.state?.from?.pathname
			? location.state.from.pathname + location.state?.from?.search
			: DASHBOARD_ROUTE.HOME;

		const route = handlerRoutePath(url);
		console.log(route);

		history.push(route);
	}

	return (
		<>
			<ToastContainer
				position="bottom-right"
			/>

			{showLoadingOverlayed &&
				<LoadingOverlayed />
			}
			<form onSubmit={handleSubmit} autoComplete="off" className="h-100">
				<div className="mb-3">
					<label className="form-label login" htmlFor="email">Email</label>
					<CustomInput
						handleChange={handleChange}
						id="email"
						type="email"
						values={values}
						className="form-control login"
						handleBlur={handleBlur}
						touched={touched}
						errors={errors}
					/>
				</div>
				<div className="mb-4">
					<label className="form-label login" htmlFor="password">Contraseña</label>
					<CustomInput
						handleChange={handleChange}
						id="password"
						type="password"
						isPassword
						values={values}
						className="form-control login"
						handleBlur={handleBlur}
						touched={touched}
						errors={errors}
					/>
				</div>

				<button type="submit" disabled={isSubmitting} className="w-100 btn btn-lg btn-primary mt-2 mb-4">Ingreso</button>
				<div className="passwordRecover">
					<Link to='/recuperar-cuenta'>
						¿Olvidaste tu Usuario o Contraseña?
					</Link>
				</div>
			</form>

		</>
	)
}

export default withRouter(SignInForm);
